/**
 * inline-search
 * @author Tevin
 */

@import "../../common/sassMixin";

.c-inline-search {
    margin-bottom: 10px;
    &:last-child {
        margin-bottom: 0;
    }
    &:only-child {
        padding-top: 8px;
    }
    .ant-form-inline .ant-form-item-with-help {
        margin-bottom: 0px;
    }
    .ant-form-item {
        max-width: 100%;
        min-height: 40px;
        .ant-form-item-control-input-content>.ant-input-affix-wrapper {
            width: 140px;
        }
        .ant-select {
            width: 150px;
            max-width: 100%;
        }
        .ant-picker-range {
            width: 226px;
            .ant-picker-separator {
                width: 1.5em;
                text-align: left;
            }
        }
        .c-plain-select,
        .c-china-address {
            display: inline-block;
        }
        .c-text-ignore {
            font-family: serif, monospace;
        }
        .c-input-range {
            width: 178px;
            &.c-input-range-suffix {
                width: 202px;
            }
        }
    }
    .c-inline-search-group {
        display: inline-block;
        .ant-row.ant-form-item {
            display: inline-block;
            margin-right: -2px;
            &:last-child {
                margin-right: 16px;
            }
        }
    }
    .c-line-search-fold {
        &.open {
            .anticon-double-right {
                @include prefixer(transform, rotate(-90deg));
            }
        }
        &.close {
            .anticon-double-right {
                @include prefixer(transform, rotate(90deg));
            }
        }
    }
    @media screen and (max-width: 575px) {
        .ant-form-item {
            width: 100%;
            &.c-inline-search-nolabel {
                width: auto;
            }
            .ant-form-item-label {
                flex: 1;
            }
            .ant-form-item-control {
                flex: 2;
            }
            .ant-form-item-control-input-content>.ant-input-affix-wrapper {
                width: 100%;
            }
            .c-plain-select {
                width: 100%;
                .ant-select {
                    width: 100%;
                }
            }
            .c-china-address {
                width: 100%;
            }
        }
        .ant-form-item-label {
            width: auto;
            padding-top: 3px;
            padding-bottom: 0;
            line-height: 30px;
            label {
                padding-right: 5px;
            }
        }
        .ant-form-item-control-wrapper {
            width: auto;
        }
        .c-inline-search-nolabel {
            .ant-form-item-control-input {
                padding-top: 5px;
            }
        }
    }
    @media screen and (max-width: 390px) {
        .ant-form-item {
            .c-date-picker {
                width: 100%;
                padding: 4px 6px;
                input {
                    height: 22px;
                    font-size: 12px;
                    text-align: center;
                }
                .ant-picker-range-separator {
                    width: 14px;
                    padding: 0 1px;
                    .ant-picker-separator {
                        width: 14px;
                        .anticon {
                            font-size: 14px;
                        }
                    }
                }
                .ant-picker-clear {
                    right: 6px;
                }
            }
        }
    }
}

.c-inline-search-arrage {
    @media screen and (min-width: 576px) {
        .ant-form-item,
        .c-inline-search-group {
            width: 250px;
        }
        .c-inline-search-nolabel {
            width: auto;
        }
        .ant-form-item {
            .ant-form-item-label {
                width: 72px;
            }
            .ant-form-item-control-input-content> {
                .ant-input-affix-wrapper,
                .c-date-picker,
                .c-input-range,
                .c-china-address {
                    width: 180px !important;
                }
                .c-plain-select {
                    .ant-select {
                        width: 180px !important;
                    }
                }
            }
            .c-date-picker {
                padding: 4px 6px;
                input {
                    height: 22px;
                    font-size: 12px;
                    text-align: center;
                }
                .ant-picker-range-separator {
                    width: 14px;
                    padding: 0 1px;
                    .ant-picker-separator {
                        width: 14px;
                        .anticon {
                            font-size: 14px;
                        }
                    }
                }
                .ant-picker-clear {
                    right: 6px;
                }
            }
            .c-china-address {
                .ant-cascader-picker-label {
                    padding: 0 18px 0 6px;
                    font-size: 12px;
                }
                .ant-cascader-picker-arrow,
                .ant-cascader-picker-clear {
                    right: 9px;
                    font-size: 14px;
                }
            }
        }
    }
}