/**
 * cTableForm
 * @author Tevin
 */

@import "../../common/sassMixin";

.c-table-form-table {
    position: relative;
    .ant-table {
        .c-table-title-helper {
            .anticon-question-circle {
                margin-left: 3px;
                color: #a6a6a6;
            }
        }
        .ant-table-tbody>tr>td {
            padding: 6px 4px;
        }
        .ant-table-row {
            position: relative;
            cursor: pointer;
            &.selected {
                background-color: #c2dffd;
                .ant-table-cell-fix-left,
                .ant-table-cell-fix-right {
                    background-color: #c2dffd;
                }
            }
            &.selected:hover>td {
                background-color: #c2dffd;
            }
        }
        .ant-table-cell {
            button+button {
                margin-left: 6px;
            }
        }
    }
    .c-resizeable-head {
        overflow: visible;
        background-clip: padding-box;
        .c-resizable-handle {
            @include position(absolute, n n 0 -6px, 1);
            width: 12px;
            height: 100%;
            cursor: col-resize;
        }
    }
    .c-table-form-header-required {
        &::before {
            display: inline-block;
            margin-right: 4px;
            color: #ff4d4f;
            font-size: 14px;
            font-family: SimSun, sans-serif;
            line-height: 1;
            content: '*';
        }
    }
    .c-expand-ellipsis {
        position: relative;
        .trigger {
            @include position(absolute, 0 n n 0);
            width: 26px;
            max-width: 50%;
            height: 100%;
            text-align: center;
            line-height: 45px;
            background-color: #fff;
            border-left: #e6e6e6 1px solid;
            cursor: pointer;
        }
        .ant-table-expanded-row-fixed+.trigger {
            display: none;
        }
    }
    .c-expand-ellipsis-off {
        text-overflow: clip;
    }
    .c-table-summary {
        text-align: center;
    }
    .c-table-form-cell-error {
        box-shadow: inset 0 0 3px 3px rgba(#ff4d4f, 0.3), inset 0 0 1px 1px #ff4d4f;
    }
    .c-table-form-cell-focus {
        background-color: #ffdf99 !important;
    }
    .c-table-form-item-box {
        width: 100%;
        height: 100%;
        .c-table-form-item {
            width: calc(100% - 10px);
            text-align: left;
            &.ant-checkbox-wrapper,
            &.ant-checkbox-group,
            &.ant-radio-wrapper,
            &.ant-radio-group,
            &.ant-switch {
                width: auto;
            }
            &.ant-checkbox-wrapper {
                line-height: 31px;
            }
        }
    }
}

.c-rich-table-ellipsis-floating {
    @include position(absolute, 0 0, 3);
    max-width: 500px;
    background-color: #fff;
    box-shadow: 0 2px 6px rgba(#000, 0.2);
    .anticon-close {
        @include position(absolute, -13px n n -13px);
        width: 26px;
        height: 26px;
        padding: 5px 0 0;
        color: #fff;
        font-size: 16px;
        text-align: center;
        line-height: 0;
        background-color: #999;
        border-radius: 50%;
        @include transition;
        &:hover {
            background-color: #666;
        }
    }
    .inner {
        padding: 11px 16px 12px 8px;
    }
}