/**
 * cTableForm
 * @author Tevin、chensi
 */

@import "../../common/sassMixin";

.c-table-form {
    .ant-table.ant-table-small .ant-table-title {
        padding: 4px 8px;
    }
    .c-table-form-header {
        @include flexbox(flex, flex-start center);
        line-height: 32px;
        .c-table-form-header-title {
            font-size: 16px;
            white-space: nowrap;
            .c-text-ignore {
                margin-left: 8px;
                font-size: 14px;
                font-family: serif, monospace;
            }
        }
        .c-table-form-header-btns {
            .ant-btn {
                margin-left: 8px;
                .anticon {
                    width: 1em;
                    height: 1em;
                    &.c-icon-row-add-new,
                    &.c-icon-row-insert {
                        color: $colorSuccess;
                    }
                    &.c-icon-row-remove,
                    &.c-icon-row-clear-all {
                        color: $colorDanger;
                    }
                    &.c-icon-row-copy {
                        color: $colorWarning;
                    }
                }
                &:disabled {
                    .anticon {
                        color: rgba(0, 0, 0, 0.25);
                    }
                }
                .anticon+span {
                    margin-left: 4px;
                }
            }
        }
        .c-table-form-header-help {
            display: inline-block;
            font-size: 12px;
            font-style: italic;
            color: #ddd;
        }
    }
}